/* istanbul ignore file */
import { useMemo } from 'react';

import { Search } from '@vakantiesnl/types';

import { SearchBody } from './search-body';
import { usePartyComposition } from '../stores/globalFiltersStore';
import { sortObject } from '../util/sortObjectKeys';

export const CITY_TRIP_OFFSET = 100;

export const createGeoContextRequest = (body: Search.VaknlSearchBody): RequestInfo => {
	const sortedBody = JSON.stringify(sortObject(body));

	return new Request(`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/geo-context-filters`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: sortedBody,
	});
};

export const createVaknlSearchRequest = (body: Search.VaknlSearchBody): RequestInfo => {
	return new Request(`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/search`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	});
};

type AccommodationOffersRequestProps = {
	giataId: number;
	departureDate?: string;
	durationDays: number[];
	departureAirports?: string[];
	mealplans?: string[];
	airlines?: string[];
	flightProvider?: string;
	tourOperators?: string[];
	minPrice?: string;
	maxPrice?: string;
};

const useOfferRequest = (searchBody: string, giataId?: number): RequestInfo | undefined =>
	useMemo(
		(): RequestInfo | undefined =>
			!giataId
				? undefined
				: new Request(
						`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${giataId}/offers?check_availability=true`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: searchBody,
						},
					),
		[searchBody, giataId],
	);

export type OfferResponse = {
	results_total: number;
	results_per_page: number;
	results_from: number;
	accommodations: Search.RawCompactAccommodationItem[];
	party: Search.RawParty;
};

export const useCreateAccommodationOffersRequest = ({
	giataId,
	departureDate,
	durationDays,
	departureAirports,
	mealplans,
	airlines,
	flightProvider,
	tourOperators,
	minPrice,
	maxPrice,
}: AccommodationOffersRequestProps): RequestInfo => {
	const party = usePartyComposition();

	const formattedDepartureDate = departureDate ? departureDate : undefined;
	const searchBody = SearchBody.createDepartureDateRequest(party)
		.appendDepartureDate(formattedDepartureDate)
		.appendDurationsFlight(durationDays?.map((value) => value - 1))
		.appendDepartureAirports(departureAirports)
		.appendMealplans(mealplans)
		.appendAirlines(airlines)
		.appendFlightProvider(flightProvider)
		.appendTourOperators(tourOperators)
		.appendMinPrice(minPrice)
		.appendMaxPrice(maxPrice)
		.toString();

	return useOfferRequest(searchBody, giataId) as RequestInfo;
};

type FlightAlternativesRequestProps = {
	giataId: number;
	departureDate?: string;
	departureAirport?: string | string[];
	duration?: number;
	mealplans?: string[];
	airlines?: string[];
	flightProvider?: string;
	tourOperators?: string[];
	minPrice?: string;
	maxPrice?: string;
};

export const useCreateFlightAlternativesRequest = (
	props: FlightAlternativesRequestProps | undefined,
): RequestInfo | undefined => {
	const party = usePartyComposition();
	const searchBody = SearchBody.createFlightAlternativesRequest(party)
		.appendDepartureDate(props?.departureDate)
		.appendDurationAccommodation(props?.duration)
		.appendDepartureAirport(props?.departureAirport)
		.appendMealplans(props?.mealplans)
		.appendAirlines(props?.airlines)
		.appendFlightProvider(props?.flightProvider)
		.appendTourOperators(props?.tourOperators)
		.appendMinPrice(props?.minPrice)
		.appendMaxPrice(props?.maxPrice)
		.toString();
	const giataId = props?.giataId;

	return useMemo(
		(): RequestInfo | undefined =>
			!giataId
				? undefined
				: new Request(
						`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${giataId}/flight-alternatives?check_availability=true`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: searchBody,
						},
					),
		[giataId, searchBody],
	);
};

export type FlightAlternativesResponse = {
	results_total: number;
	results_per_page: number;
	results_from: number;
	accommodations: Search.RawCompactAccommodationItem[];
};

type RoomOffersRequestProps = {
	giataId: number;
	departureDate?: string;
	duration: number;
	departureAirport: string;
	arrivalAirport: string;
	tourOperator: string;
	inboundFlightCode: string;
	outboundFlightCode: string;
	inboundFlightId?: string;
	outboundFlightId?: string;
	mealplans?: string[];
	airlines?: string[];
	flightProvider?: string;
	minPrice?: string;
	maxPrice?: string;
};

export const useCreateRoomOffersRequest = (props: RoomOffersRequestProps | undefined): RequestInfo | undefined => {
	const party = usePartyComposition();
	const searchBody = SearchBody.createRoomTypeRequest(party)
		.appendDepartureDate(props?.departureDate)
		.appendDurationAccommodation(props?.duration)
		.appendDepartureAirport(props?.departureAirport)
		.appendArrivalAirport(props?.arrivalAirport)
		.appendTourOperator(props?.tourOperator)
		.appendInboundFlightId(props?.inboundFlightId)
		.appendOutboundFlightId(props?.outboundFlightId)
		.appendInboundFlightCode(props?.inboundFlightCode)
		.appendOutboundFlightCode(props?.outboundFlightCode)
		.appendMealplans(props?.mealplans)
		.appendAirlines(props?.airlines)
		.appendFlightProvider(props?.flightProvider)
		.appendMinPrice(props?.minPrice)
		.appendMaxPrice(props?.maxPrice)
		.toString();
	const giataId = props?.giataId;

	return useOfferRequest(searchBody, giataId);
};

type MealPlanOfferRequestProps = {
	giataId: number;
	departureDate?: string;
	duration: number;
	departureAirport: string;
	arrivalAirport: string;
	tourOperator: string;
	inboundFlightCode: string;
	outboundFlightCode: string;
	inboundFlightId?: string;
	outboundFlightId?: string;
	provider: string;
	roomsCodes: string[][];
	roomsKeys?: string[];
	mealplans?: string[];
	airlines?: string[];
	flightProvider?: string;
	opCodes?: string[];
	minPrice?: string;
	maxPrice?: string;
};

export const useCreateMealPlanOffersRequest = (
	props: MealPlanOfferRequestProps | undefined,
): RequestInfo | undefined => {
	const party = usePartyComposition();
	const searchBody = SearchBody.createMealplanRequest(party)
		.appendDepartureDate(props?.departureDate)
		.appendDurationAccommodation(props?.duration)
		.appendDepartureAirport(props?.departureAirport)
		.appendArrivalAirport(props?.arrivalAirport)
		.appendTourOperator(props?.tourOperator)
		.appendInboundFlightId(props?.inboundFlightId)
		.appendOutboundFlightId(props?.outboundFlightId)
		.appendInboundFlightCode(props?.inboundFlightCode)
		.appendOutboundFlightCode(props?.outboundFlightCode)
		.appendProvider(props?.provider)
		.appendRoomsCodes(props?.roomsCodes)
		.appendRoomsBookingCodes(props?.opCodes)
		.appendRoomsKeys(props?.roomsKeys)
		.appendMealplans(props?.mealplans)
		.appendAirlines(props?.airlines)
		.appendFlightProvider(props?.flightProvider)
		.appendMinPrice(props?.minPrice)
		.appendMaxPrice(props?.maxPrice)
		.toString();

	const giataId = props?.giataId;

	return useOfferRequest(searchBody, giataId);
};
